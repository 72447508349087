<template>
  <div class="page">
    <navTop :title="title" :leftIcon="true"></navTop>
    <!-- <div class="home-title">重点招商企业</div> -->
    <!-- 内容 -->
    <div class="home-sub">
      <div @click="showTab">
        {{ categoryTabText }}
        <van-icon name="arrow-down" color="#fff" v-if="!show"/>
         <van-icon name="arrow-up" color="#fff" v-if="show"/>
      </div>
      <div class="mask" v-if="show" @click.stop>
        <div class="home-sub-box">
          <div class="home-tab">
            <div
              class="home-tab-item"
              :class="current == item.id ? 'home-item-select' : ''"
              v-for="(item, index) in tabList"
              :key="index"
              @click.stop="setCurrent(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="home-sub-btn">
            <div class="cancel" @click="showTab">取消</div>
            <div class="sure" @click="sureBtn">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="companyPageList"
            class="business-list"
          >
            <div
              class="business-item"
              v-for="(item, index) in list"
              :key="index"
            >
              <!-- <div class="business-item-num">编号：{{ item.weigh }}</div> -->
              <van-tag v-if="companyType != '3'" class="introduce-tag" color="rgba(109, 145, 227, 0.1)" text-color="#5B6DF5" @click="introduceDialog(item.describe)">企业简介</van-tag>
              <div class="business-item-title">{{ item.name }}</div>
              <div class="business-item-name">{{ item.remark ? "备注：" + item.remark : "" }}</div>
              <div class="business-item-btn">
                <div class="business-item-btn-left">
                  <div
                    class="business-item-msg"
                    v-for="(subItem, subIndex) in item.categorys"
                    :key="subIndex"
                  >
                    {{ subItem.name }}
                  </div>
                </div>
                <!-- <div class="business-item-look" @click="goDetails(item.id)">
                  <img src="../assets/imgs/next_right.png" class="next-icon" />
                  点击查看
                </div> -->
              </div>
            </div>
          </van-list>
        </div>
      </van-pull-refresh>
    </div>

    <van-dialog class="dialog-com" v-model="introduceShow">
      <div class="dialog-content" v-html="companyIntroduce"></div>
    </van-dialog>
  </div>
</template>

<script>
import { getSubCategory, companyPageList } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
export default {
  components: {
    navTop,
  },
  data() {
    return {
      title:'',
      page: 1,
      pages: 1,
      current: 0,
      tabList: [],
      show: false,
      list: [],

      loading: false,
      finished: false,
      refreshing: false,
      categoryTabText: "区域",
      introduceShow: false,
      companyIntroduce: ""
    };
  },
  created() {
    let id = this.$route.query.id;
    let keywords = this.$route.query.keywords;
    this.title = this.$route.query.title;
    if (id && keywords) {
      this.getSubCategory(id);
      this.companyType = keywords;
      this.companyPageList();
    } else {
      this.$router.push("/home");
    }
  },
  methods: {
    // 企业简介
    introduceDialog(e){
      if(e == "") {
        this.$toast('暂无简介')
      } else {
        this.companyIntroduce = e
        this.introduceShow = true
      }
    },
    // 企业列表
    async companyPageList() {
      if (this.page > this.pages) {
        this.finished = true;
        return false;
      }
      let params = {
        page: this.page,
        type: this.companyType,
        category_id: this.current,
      };
      this.loading = true;
      try {
        const { code, data } = await companyPageList(params);
        let list = this.list.concat(data.data);
        this.list = list;
        this.page++;
        this.pages = data.last_page;
        this.loading = false;
        this.refreshing = false;
      } catch (e) {
        console.log(e);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.initCompanyList();
    },
    // 区域列表
    async getSubCategory(id) {
      try {
        const { code, data } = await getSubCategory({ category_id: id });
        this.tabList = [{ id: 0, name: "全部" }].concat(data);
      } catch (e) {
        console.log(e);
      }
    },
    goDetails(id) {
      this.$router.push({
        path: "/companyDetails",
        query: {
          id: id,
        },
      });
    },
    // 初始化列表
    initCompanyList() {
      this.page = 1;
      this.pages = 1;
      this.list = [];
      this.companyPageList();
    },
    sureBtn() {
      this.initCompanyList();
      this.tabList.forEach(element => {
        if(element.id === this.current) {
          this.categoryTabText = element.name
        }
      });
      this.showTab();
    },
    showTab() {
      this.show = !this.show;
    },
    setCurrent(e) {
      this.current = e;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.business-item-btn-left {
  display: flex;
  align-items: center;
}
.home{
    background: linear-gradient(135deg, #aac0ff, #FFF);
}
.home-title {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
  padding-left: 20px;
}
.home-sub {
  /* border: 1px solid #dbdeee; */
  background-color: #556efe;
  padding-right: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  position: relative;
  text-align: right;
}
.business-next-down {
  width: 13px;
  height: 10px;
}
.business-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}
.business-item-title {
  font-size: 18px;
  margin-bottom: 5px;
  color: #000;
  font-weight: 700;
}
.business-item-num {
  font-size: 18px;
  color: #556efe;
  font-weight: 600;
  margin-bottom: 10px;
}
.business-item-name {
  font-size: 14px;
  margin-bottom: 10px;
  color: #989898;
  font-weight: 500;
}
.business-item-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.business-item-msg {
  color: #E49D32;
  background-color: rgba(244, 191, 111, 0.2);
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 4px;
}
.business-item-look {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #556efe;
}
.next-icon {
  width: 8px;
  height: 10px;
  margin-right: 4px;
  margin-left: 7px;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 10;
  height: 100vh;
}
.home-sub-box {
  padding: 15px;
  background-color: #fff;
}
.home-sub-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 25px;
  margin-top: 10px;
}
.cancel {
  flex: 1;
  color: #666;
  text-align: center;
}
.sure {
  flex: 1;
  text-align: center;
  color: #556efe;
}
.home-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.home-tab-item {
  background-color: #f3f3f3;
  border-radius: 15px;
  padding: 5px 10px;
  line-height: 22px;
  font-size: 14px;
  min-width: 75px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #666;
  text-align: center;
}
.home-tab .home-item-select {
  border: 1px solid #eee;
  color: #556efe;
  background-color: #fff;
}
.introduce-tag{
  position: absolute;
  top: 14px;
  right: 15px;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 16px;
}
.dialog-com{
  padding: 0 20px;
  font-size: 14px;
}
.dialog-content{
  max-height: 320px;
  min-height: 280px;
  overflow-y: auto;
  font-size: 14px;
  margin-top: 15px;
}
</style>
